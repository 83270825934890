import React from 'react'
import {Dropdown, Button, Form, Modal} from "react-bootstrap";
import {inject, observer} from "mobx-react";

@inject("authStore") @observer
class LoginForm extends React.Component {
    render() {
        const s = this.props.authStore;
        return (
            <Form noValidate autoComplete={"chrome-off"}>
                <div className={"row"}>
                    <div className={"col-md-10"}>
                        <h1>חיוב מאובטח לסוכני הנסיעות</h1>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-4"}>
                        <label className={"ui-label"}>שם משתמש</label>
                        <Form.Control
                            id={"3ds-dasdsadsa-input"}
                            autoComplete="none"
                            type={"text"}
                            name={"3ds-fsdfdfd"}
                            maxLength={30}
                            value={s.username}
                            onChange={(e) => {
                                console.log(e.target.value);
                                s.setUsername(e.target.value);
                            }}
                        />
                    </div>

                    <div className={"col-md-4"}>
                        <label className={"ui-label"}>סיסמא</label>
                        <Form.Control
                            id={"3ds-pass-input"}
                            autoComplete="new-password"
                            type={"password"}
                            name={"3ds-password"}
                            maxLength={30}
                            value={s.password}
                            onChange={(e) => {
                                s.setPassword(e.target.value);
                            }}
                        />
                    </div>

                    <div className={"col-md-2"}>
                        <label className={"ui-label"}>&nbsp;</label>
                        <Button onClick={() => {s.performLogin();}}>כניסה</Button>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <br/><br/>
                        {s.error &&
                        <div className="alert alert-danger"  role="alert">
                            {s.error}
                        </div>
                        }
                    </div>
                </div>
            </Form>
        )
    }
}

export {LoginForm}