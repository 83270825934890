import {TimeStore} from "./time.store";
import {FormStore} from "./form.store";
import {ModalStore} from "./modal.store";
import {AuthStore} from "stores/auth.store";


const allStores = {
    authStore : new AuthStore(),
    timeStore : new TimeStore(),
    submitModal : new ModalStore(),
    validationModal : new ModalStore(),
    paymentModal : new ModalStore(),
    serverErrorModal : new ModalStore()
};

allStores.formStore = new FormStore(allStores)


export default allStores;