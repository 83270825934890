import React from 'react';
import {Form} from "react-bootstrap";
import {observer} from "mobx-react";

@observer
class NumberField extends React.Component {
    render() {
        if (!this.props.field) {
            return null;
        }
        const f = this.props.field;

        const d = {};
        if (f.required) {
            d.required = true;
        }

        return (
            <div style={{"direction" : this.props.direction}}>
                <label className={"ui-label"} htmlFor={f.id}>{f.title}</label>
                <Form.Control id={f.id}
                              type={f.type.toLowerCase()}
                              className="form-control"
                              placeholder={f.placeholder}
                              value={f.value}
                              onChange={(e) => {
                                f.setValue(e.target.value);
                                }}
                              isInvalid={!f.isValid} {...d}
                              maxLength={this.props.maxLength}
                              onFocus={this.props.onFocus}
                              onBlur={this.props.onBlur}

                />
            </div>
        )
    }
}

NumberField.defaultProps = {
    field : null,
    direction : "rtl",
    maxLength : 100,
    onFocus : () => {},
    onBlur : () => {}
}

export {NumberField}