import {action, computed, observable} from "mobx";
import axios from 'axios';
import access from 'safe-access';
//axios.defaults.withCredentials = true

const DEFAULT_CONFIG = { headers: { "Content-Type": "application/json" } };
const MULTIPART_CONFIG = { headers: { "Content-Type": "multipart/form-data" } };
const URLENCODED_CONFIG = { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }


class AuthStore {
    @observable username = "";
    @observable password = "";

    @observable serverUser = null;
    @observable userType = "";
    @observable isLoading = false;
    @observable token = "";

    @observable error = "";

    constructor() {
       this.checkAuth();
    }

    @action setUsername(value) {
        this.username = value;
    }

    @action setPassword(value) {
        this.password = value;
    }

    @action performLogin() {
        const url = process.env.REACT_APP_API_URL + '/api/auth';

        if (this.username.length < 3 || this.username.password < 3) {
            this.error = 'שם המשתמש או הסיסמה שגויים';
            return;
        }

        const data = {"Username" : this.username, "Password" : this.password};
        this.error = "";

        axios.post(url, data, {headers: { "Content-Type": "application/json" }, withCredentials:false})
            .then((result) => {
                console.log("result",result);
                const token = access(result,'data.token');
                if (token) {
                    this.token = token;

                    window.sessionStorage.setItem("access_token",token);
                    this.checkAuth();
                } else {
                    this.error = 'שם המשתמש או הסיסמה שגויים';
                }
            })
            .catch((e) => {
                console.error(e);
                this.error = 'שם המשתמש או הסיסמה שגויים';
            })
    }

    @action logout() {
        window.sessionStorage.removeItem('access_token');
        this.checkAuth();
    }
    @action checkAuth() {
        const url = process.env.REACT_APP_API_URL + '/api/is-auth';
        this.isLoading = true;

        axios.get(url)
            .then((result) => {
                console.log(result);
                const u = access(result, 'data.username')
                const type = access(result,'data.type');

                if (u) {
                    this.serverUser = u;
                    this.userType = type;
                } else {
                    this.serverUser = null;
                    this.userType = "";
                }
            })
            .catch((e) => {
                console.log(e)
                this.serverUser = null;
                this.type = "";
            })
            .finally(() =>{
                this.isLoading = false;
            })
    }

    @computed get isAuthenticated() {
        return !!this.serverUser;
    }
}

export {AuthStore}