import React from 'react'
import {Button} from "react-bootstrap";
import {history} from "components/services/custom.router";
import {inject, observer} from "mobx-react";

@inject("formStore","paymentModal") @observer
class SuccessPage extends React.Component {
    render() {
        const s = this.props.formStore;

        return (
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <h1>הטופס נשלח בהצלחה!</h1>
                    <br/><br/>
                    {(s && s.totalPriceInNIS>0) &&
                    <Button variant="primary btn-lg" onClick={() => {
                        s.setShowResetButton(true);
                        this.props.paymentModal.closeModal();
                        history.push(process.env.REACT_APP_BASE_ROUTE+'/');
                    }}>חזרה לדף הבית</Button>
                    }
                </div>
            </div>
        )
    }
}

export {SuccessPage}