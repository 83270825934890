/*
const PaymentTypes = {
    "REGULAR" : 1,
    "PAYMENTS" : 8,
    "CREDIT" : 6
};
 */

const PaymentTypes = {
    "REGULAR" : "REGULAR",
    "PAYMENTS" : "PAYMENTS",
    "PAYMENTS_WITH_INTEREST" : "PAYMENTS_WITH_INTEREST",
    "CREDIT" : "CREDIT"
};


const getPaymentTypeValueForTranzila = (paymentType) => {
    switch (paymentType) {
        case PaymentTypes.PAYMENTS:
        case PaymentTypes.PAYMENTS_WITH_INTEREST:
            return 8;
        case PaymentTypes.CREDIT:
            return 6;

        default:
            return 1;
    }
}

export {PaymentTypes, getPaymentTypeValueForTranzila}