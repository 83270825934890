import React from 'react'
import {inject, observer} from "mobx-react";
import {Dropdown, Button, Form, Modal} from "react-bootstrap";
import {FormField} from "./field";
import {PaymentForm} from "./payment.form";
import {TextField} from "components/form/text.field";
import {PaymentTypes} from "components/enums/payment.type";
import {LoginForm} from "components/login/login.form";

@inject ("formStore","validationModal", "authStore") @observer
class FullForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validated : false
        }
    }
    render() {
        const s = this.props.formStore;
        const auth = this.props.authStore;

        if (!this.props.authStore.isAuthenticated) {
            return <LoginForm/>
        }

        const handleSubmit = (event) => {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }

            this.setState({
                validated : true
            });

            s.validateForm();

            event.preventDefault();
            event.stopPropagation();
            //setValidated(true);


            if (!s.isFormValid) {
                this.props.validationModal.openModal();
                return;
            }

            s.postForm();
        };

        if (s.isSubmitted) {
            return (
                <div className="alert alert-success" role="alert">
                    הטופס נשלח בהצלחה.
                </div>

            )
        }

        return (
            <div>
                <Form noValidate onSubmit={handleSubmit}>

                    <div className={"row"}>
                        <div className={"col-md-8"}>
                            <h1>חיוב מאובטח לסוכני הנסיעות</h1>
                        </div>


                        <div className={"col-md-4"} style={{"textAlign":"left"}}>
                            {auth.isAuthenticated &&
                            <Button variant={"secondary"} onClick={() => {
                                let c = window.confirm("האם לצאת?");
                                if (c) {
                                    auth.logout();
                                }
                            }}>יציאה ({auth.serverUser})</Button>
                            }
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-2"}>
                            {(s.showResetButton) &&
                                <div>
                            <Button variant={"success"} onClick={() => {
                                let c = window.confirm("האם לנקות פרטים?");
                                if (c) {
                                    s.resetData();
                                }
                            }}>נקה פרטים</Button>
                                    <br/><br/>
                                </div>
                            }
                        </div>


                    </div>

                    <div className={"row"}>
                        <div className="form-group col-md-6">
                            <FormField field={s.agencyName} maxLength={30}/>
                        </div>

                        <div className="form-group col-md-6">
                            <FormField field={s.agentName} maxLength={30}/>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className="form-group col-md-6">
                            <FormField field={s.orderNumber} maxLength={500}/>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-3"}>
                            <TextField field={s.price}/>
                        </div>

                        <div className={"col-md-3"}>
                            <label className={"ui-label"}>מטבע</label>
                            <Form.Check name={"currency_field"}
                                        id={"currency_field_1"}
                                        type={"radio"}
                                        label={'דולר ארה"ב'}
                                        checked={s.currency === "USD"}
                                        onChange={() => {
                                            s.setCurrency("USD");
                                        }}/>

                            <Form.Check name={"currency_field"}
                                        id={"currency_field_2"}
                                        type={"radio"}
                                        label={"אירו"}
                                        checked={s.currency === "EUR"}
                                        onChange={() => {
                                            s.setCurrency("EUR");
                                        }}/>
                        </div>

                        <div className={"col-md-3"}>
                            <label className={"ui-label"}>סוג תשלום</label>
                            <Form.Check name={"ptype_field"}
                                        id={"ptype_field_1"}
                                        type={"radio"}
                                        label={'תשלום רגיל'}
                                        checked={s.paymentType === PaymentTypes.REGULAR}
                                        onChange={() => {
                                            s.setPaymentType(PaymentTypes.REGULAR);
                                        }}/>

                            {auth.userType === 'FULL' &&
                            <Form.Check name={"ptype_field"}
                                        id={"ptype_field_4"}
                                        type={"radio"}
                                        label={'תשלומים'}
                                        checked={s.paymentType === PaymentTypes.PAYMENTS}
                                        onChange={() => {
                                            s.setPaymentType(PaymentTypes.PAYMENTS);
                                        }}/>
                            }

                            <Form.Check name={"ptype_field"}
                                        id={"ptype_field_2"}
                                        type={"radio"}
                                        label={"תשלומים עם ריבית"}
                                        checked={s.paymentType === PaymentTypes.PAYMENTS_WITH_INTEREST}
                                        onChange={() => {
                                            s.setPaymentType(PaymentTypes.PAYMENTS_WITH_INTEREST)
                                        }}/>

                            <Form.Check name={"ptype_field"}
                                        id={"ptype_field_3"}
                                        type={"radio"}
                                        label={"קרדיט"}
                                        checked={s.paymentType === PaymentTypes.CREDIT}
                                        onChange={() => {
                                            s.setPaymentType(PaymentTypes.CREDIT)
                                        }}/>
                        </div>

                        <div className={"col-md-3"}>
                            {s.paymentType !== PaymentTypes.REGULAR &&
                                <React.Fragment>
                                <label className={"ui-label"}>מס' תשלומים</label>
                            <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    {s.numOfPaymentsObj &&
                                        <span>{s.numOfPaymentsObj.name}</span>
                                    }

                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {s.paymentsOptions.map((o,ind) => {
                                        return <Dropdown.Item key={"opt" + ind} onClick={() => {
                                            s.setNumOfPaymentsObj(o);
                                        }}>{o.name}</Dropdown.Item>
                                    })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                                </React.Fragment>
                            }

                        </div>


                    </div>

                    <div className={"row"}>
                        <div className={"col-md-3"}>
                            <label className={"ui-label"}>סכום בש&quot;ח</label>
                            <Form.Control type={"text"} value={"₪" + s.totalPriceInNIS} readOnly={true}/>
                        </div>
                    </div>


                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <br/>
                            <PaymentForm/>
                        </div>
                    </div>

                <div className={"row"}>



                    <div className={"col-md-3"}>
                        <br/>
                        <Button type={"submit"} variant="primary btn-lg">המשך לתשלום</Button>

                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-md-12"}><br/></div>
                </div>
                </Form>
        </div>);

    }
}

export {FullForm}