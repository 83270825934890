import axios from 'axios'
import access from 'safe-access'

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    console.log("request",config);
    const token = window.sessionStorage.getItem("access_token");
    console.log("token", token);
    if (token && !config.ignoreAuth) {
        console.log("set API token", token);
        config.headers["accesstoken"] = token;
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});



axios.interceptors.response.use(function (response) {
    console.log("intercept" ,response);

    if (response.config.method === "post") {
        //    stores.transportStore.decrementCount();
    }

    return response;
}, function (error) {
    console.log("intercept error" ,error);

    if (error.config.method === "post") {
        //    stores.transportStore.decrementCount();
    }

    const status = access(error, 'response.status');
//    const message = access(error, 'response.data.message');

    if (status === 401 || status === 405) {
        //history.push("/login");
    }


    // Do something with response error
    return Promise.reject(error);
});