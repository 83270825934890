import access from 'safe-access';
import {observable, action, computed} from "mobx";
import {isBlankString, isPhoneNumberValid, isAlphaNumeric} from "../utils/validator";
import axios from 'axios';
import {FieldTypes, TextField, NumberField} from "stores/fields";
import moment from "moment";
import {PaymentTypes, getPaymentTypeValueForTranzila} from "components/enums/payment.type";

const DEFAULT_CONFIG = { headers: { "Content-Type": "application/json" } };
const MULTIPART_CONFIG = { headers: { "Content-Type": "multipart/form-data" } };
const URLENCODED_CONFIG = { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }

const interestArray = [];
interestArray[2] = 2.0;
interestArray[3] = 2.5;
interestArray[4] = 3.0;
interestArray[5] = 3.5;
interestArray[6] = 4.5;
interestArray[7] = 5.5;
interestArray[8] = 7.0;
interestArray[9] = 8.0;
interestArray[10] = 9.0;
interestArray[11] = 10.0;
interestArray[12] = 11.0;


function fixedEncodeURIComponent (str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}


class FormStore {
    @observable agencyName = new TextField("שם הסוכנות", true);
    @observable agentName = new TextField("שם הסוכן", true);

    @observable orderNumber = new TextField("מספר הזמנה", true);
    @observable currency = "USD";

    @observable price = new NumberField("הכנס סכום",true);

    @observable showResetButton = false;


    // payment information
    @observable paymentType = PaymentTypes.REGULAR;
    @observable numOfPaymentsObj = null;

    @observable ccName = new TextField("שם מלא", true);
    @observable ccPhone = new TextField("טלפון נייד", true);


    @observable isSubmitted = false;
    @observable destinationsData = null;
    @observable selectedDestination = null;
    @observable validationMessages = [];
    @observable serverTime = null;

    @observable ref = "";
    @observable currencies = [];

    @observable paymentUrl;
    @observable iframeResult;

    constructor(root) {
        this._root = root;

        this.fetchServerTime();
        this.fetchCurrencies();

        this.price.setMinValue(0);

        var qs = (function(a) {
            if (a == "") return {};
            var b = {};
            for (var i = 0; i < a.length; ++i)
            {
                var p=a[i].split('=', 2);
                if (p.length == 1)
                    b[p[0]] = "";
                else
                    b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
            }
            return b;
        })(window.location.search.substr(1).split('&'));

        console.log("params", qs);
        this.ref = qs['ref'];

        if (process.env.REACT_APP_DUMMY_DATA) {
            this.agencyName.setValue("Test Agency");
            this.agentName.setValue("Test Name");

            this.ccName.setValue("Test Name");
           // this.ccEmail.setValue("test@test.com");
            this.ccPhone.setValue("0555555555");
        }

    }

    @action resetData() {
        this.agencyName.setValue("");
        this.agentName.setValue("");
        this.orderNumber.setValue("");

        this.price.setValue("");
        this.ccName.setValue("");
        this.ccPhone.setValue("");
        this.setCurrency("USD");
        this.setPaymentType(PaymentTypes.REGULAR)
    }

    @action setShowResetButton(newValue) {
        this.showResetButton = newValue;
    }

    @action setPaymentType(pType) {
        this.paymentType = pType;
        if (pType !== PaymentTypes.REGULAR) {
            this.setNumOfPaymentsObj(this.paymentsOptions[0]);
        }
    }

    @action setCurrency(currency) {
        this.currency = currency;
    }

    @action fetchServerTime() {
        const d = moment(new Date());
        const url = process.env.REACT_APP_API_URL + "/api/server-time";


        axios.get(url,DEFAULT_CONFIG)
            .then((res) => {
                console.log("serverTime", res.data.serverTime);
                this.serverTime = res.data.serverTime;


                const d2 = moment(this.serverTime);

                console.log("time difference",d2.diff(d));

            })
            .catch((e) =>{
                console.error(e);
            })
    }

    @action fetchCurrencies() {
        const url = process.env.REACT_APP_API_URL + "/api/currencies";

        axios.get(url, DEFAULT_CONFIG)
            .then((res) => {
                console.log("fetchCurrencies", res.data);
                this.currencies.replace(res.data);
            })
            .catch((e) =>{
                console.error(e);
            })
    }


    @computed get isFormValid() {
        let isValid = true;

        const allFields = [
            this.agencyName,
            this.agentName,
            this.orderNumber,
            this.ccPhone,
            this.ccName
        ];

        allFields.map((f) => {
            if (!f.isValid) {
                isValid = false;
            }
        })

        if (this.totalPriceInNIS < 1) {
            isValid = false;
        }


        return isValid;
    }

    @action postForm() {
        const d = this.jsonData;

        console.log("jsonData", d);
        const url = process.env.REACT_APP_API_URL + "/api/3ds-form-full";

        this.iframeResult = "";
        this.isSubmitted = false;

        axios.post(url, d,DEFAULT_CONFIG)
            .then((res) => {
                console.log("res",res);

                const token = access(res, 'data.token');
                if (token) {

                    let url = new URL("https://direct.tranzila.com/kavei3ds/iframenew.php");
                    url.searchParams.append('lang', 'il');
                    url.searchParams.append('sum', this.totalPriceInNIS);
                    url.searchParams.append('currency',"1");
                    url.searchParams.append('r1_token',token);

                    if (this.paymentType !== PaymentTypes.REGULAR) {
                        url.searchParams.append('cred_type', getPaymentTypeValueForTranzila(this.paymentType));
                        url.searchParams.append('npay', this.numOfPayments-1);
                    }

                    url.searchParams.append('email', fixedEncodeURIComponent("asaf.daniel@daka90.co.il"));
                    url.searchParams.append('contact', fixedEncodeURIComponent(this.ccName.value));
                    url.searchParams.append('notify_url_address', encodeURI('https://pass.kavei.co.il/api/3ds-payment/'+token))
                    /*
                    const url = 'https://direct.tranzila.com/kavei3ds/iframenew.php?lang=il&sum=' + this.totalPriceInNIS
                        + '&currency=1&r1_token=' + token + '&email=' + fixedEncodeURIComponent("asaf.daniel@daka90.co.il")
                        + '&contact='+fixedEncodeURIComponent(this.ccName.value);

                     */
                    console.log("tranzila URL", url.toString());

                    this.paymentUrl = url.toString();
                    this._root.paymentModal.openModal();

                } else {
                    // show error modal
                    this._root.serverErrorModal.openModal();
                }

            })
            .catch((e) =>{
                // show error modal
                console.error(e);
                this._root.serverErrorModal.openModal();
            })
    }

    @computed get jsonData() {

        const form = {
            "agencyName" : this.agencyName.value,
            "agentName" : this.agentName.value,
            "orderNumber" : this.orderNumber.value,
            "ref"     : this.ref,

            "ccName" : this.ccName.value,
            "ccPhone" : this.ccPhone.value,

            "username" : this._root.authStore.serverUser
        }

        const payment = {
            "currency" : this.currency,
            "exchangeRate": this.exchangeRate,
            "price" : this.price.value,
            "totalPriceInNIS" : this.totalPriceInNIS,
            "paymentType" : getPaymentTypeValueForTranzila(this.paymentType),
            "paymentTypeEnum" : this.paymentType,
            "numOfPayments" : (this.paymentType === PaymentTypes.REGULAR) ? 1 : this.numOfPayments,
            "interest" : (this.paymentType === PaymentTypes.PAYMENTS_WITH_INTEREST && this.numOfPaymentsObj) ? this.numOfPaymentsObj.interest : 0,
        }

        const d = {
            "application" : "3DSPaymentForm",
            "form" : form,
            "paymentRequest" : payment
        }

        console.log("fullJSON", d);

        return d;
    }

    @action fillPaymentData() {

        if (this.ccPhone.value === "" && isPhoneNumberValid(this.mobilePhone.value)) {
            this.ccPhone.setValue(this.mobilePhone.value);
        }
    }

    @action setNumOfPaymentsObj(o) {
        this.numOfPaymentsObj = o;
    }

    @action validateForm() {
        console.log("validateForm");
        const messages = [];


        if (isBlankString(this.agencyName.value)) {
            this.agencyName.isValid = false;
            messages.push("נא להזין שם הסוכנות");
        }

        if (isBlankString(this.agentName.value)) {
            this.agentName.isValid = false;
            messages.push("נא להזין שם הסוכן");            
        }

        const isnum = /^\d+$/.test(this.orderNumber.value);

        if (isBlankString(this.orderNumber.value) || this.orderNumber.value.length != 8 || !isnum) {
            this.orderNumber.isValid = false;
            messages.push("יש להזין בשדה זה מספר הזמנה בודד, בן 8 ספרות");
        }

        // payment form
        if (isBlankString(this.ccName.value)) {
            this.ccName.isValid = false;
            messages.push("יש להזין מלא");

        }

        if (!isPhoneNumberValid(this.ccPhone.value)) {
            this.ccPhone.isValid = false;
            messages.push("יש להזין טלון נייד");
        }

        if (this.totalPriceInNIS < 1) {
            messages.push("נא להזין סכום");
        }


        this.validationMessages.replace(messages);
    }

    @computed get totalPrice() {
        if (this.selectedDestinationObj) {
            console.log("this.selectedDestinationObj",this.selectedDestinationObj);
            return this.selectedDestinationObj.price * this.passengers.length;
        }

        return 0;
    }

    @computed get exchangeRate() {
        let exchangeRate = 0;
        if (!this.currencies) {
            return 0;
        }

        this.currencies.find((c) => {
            if (c.currencyCode === this.currency) {
                exchangeRate = c.exchangeRate;
            }
        });


        return exchangeRate;
    }

    @computed get totalPriceInNIS() {
        if (this.currency === 'NIS') {
            return parseFloat(this.price.value || "0");
        }

        // other currencies
        let price = parseFloat(this.price.value || "0") * parseFloat(this.exchangeRate);

        if (([PaymentTypes.PAYMENTS, PaymentTypes.PAYMENTS_WITH_INTEREST].includes(this.paymentType)) && this.numOfPaymentsObj && this.numOfPaymentsObj.interest) {
            price = price + (price / 100 * this.numOfPaymentsObj.interest);
        }

        price = parseFloat(price).toFixed(2);
        return price;
    }

    @computed get paymentsOptions () {
        const a = [];

        if (this.paymentType === PaymentTypes.PAYMENTS) {
            for (let i=2; i<= 10; i++) {
                a.push({
                    "name" :   i.toString() + " תשלומים ללא ריבית ",
                    "value" : i,
                    "type" : PaymentTypes.PAYMENTS,
                    "interest" : 0
                });
            }
        }

        if (this.paymentType === PaymentTypes.PAYMENTS_WITH_INTEREST) {
            for (let i=2; i<= 12; i++) {
                const interest = interestArray[i] ? "(" + interestArray[i].toFixed(2) + "%)" : "";

                a.push({
                    "name" :   i.toString() + " תשלומים " + interest,
                    "value" : i,
                    "type" : PaymentTypes.PAYMENTS_WITH_INTEREST,
                    "interest" : interestArray[i] || 0
                });
            }
        }

        if (this.paymentType === PaymentTypes.CREDIT) {
            for (let i=3; i<= 18; i++) {
                a.push({
                    "name" : i.toString() + " תשלומי קרדיט",
                    "value" : i,
                    "type" : PaymentTypes.CREDIT
                });
            }
        }

        return a;
    }

    @computed get numOfPayments() {
        if (!this.numOfPaymentsObj) {
            return 1;
        }

        return this.numOfPaymentsObj.value;
    }
}

export {FormStore,FieldTypes}