import React from 'react';
import {observer, inject} from "mobx-react";
import {Modal, Button} from "react-bootstrap";

@inject("serverErrorModal", "formStore") @observer
class ServerErrorModal extends React.Component {

    render() {
        const s = this.props.serverErrorModal;

        return (
            <Modal
                show={s.isOpen}
                onHide={() => {s.closeModal()}}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title />
                </Modal.Header>
                <Modal.Body>
                    <div className="alert alert-danger" role="alert">
                            שגיאת שרת. אנא נסו שנית מאוחר יותר.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => {s.closeModal()}}
                    >
                        אישור
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export {ServerErrorModal}